<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :left-width="220">
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader include-sub @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
            class="right-tree"
            v-loading="treeLoading"
            :data="treeData"
            node-key="id"
            :props="treeProps"
            ref="unitTree"
            :load="loadUnitNode"
            lazy
            highlight-current
            @node-click="treeNodeClick">
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__scheduleunit">
          <el-table
            v-if="tableShow"
            ref="wbsTable"
            v-loading="dataListLoading"
            :data="dataList"
            row-key="id"
            lazy
            border
            :load="loadWbsChildrenData"
            highlight-current-row
            :max-height="tableHeight"
            style="width: 100%;"
            :row-style="{height: '0px'}"
            :cell-style="{padding: '2px 0'}">
            <el-table-column prop="code" label="分部分项工程编号" width="300" show-overflow-tooltip></el-table-column>
            <el-table-column prop="name" label="分部分项工程名称" show-overflow-tooltip></el-table-column>
            <!--<el-table-column prop="scheduleUnitIdName" label="形象单元名称" header-align="center" align="center" show-overflow-tooltip></el-table-column>-->
            <el-table-column label="已绑定形象单元" width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="selectScheduleUnitData&&scope.row.scheduleUnitId == selectScheduleUnitData.id" style="color: #00DD00; font-weight: bold; font-size: 14px;">{{ scope.row.scheduleUnitIdName }}</span>
                <span v-else>{{ scope.row.scheduleUnitIdName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="绑定/解绑" header-align="center" align="center" width="150">
              <template slot-scope="scope">
                <el-button size="mini" v-if="!scope.row.isLeaf && !scope.row.scheduleUnitId" @click="batchBind(scope.row)">批量绑定</el-button>
                <el-button size="mini" v-if="!scope.row.isLeaf && scope.row.scheduleUnitId" @click="batchUnbind(scope.row)">批量取消绑定</el-button>
                <el-button size="mini" v-if="scope.row.isLeaf && !scope.row.scheduleUnitId" @click="bind(scope.row)">绑定</el-button>
                <el-button size="mini" v-if="scope.row.isLeaf && scope.row.scheduleUnitId" @click="unbind(scope.row)">取消绑定</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </my-container>
    <div :class="myShakeClass">
      <span style="margin-left: 20px; color: #FF4500; font-weight: bold;" v-if="selectScheduleUnitData">当前选择的形象工程：【{{ selectScheduleUnitData.name }}】</span>
    </div>
  </el-card>
</template>

<script>
  export default {
    data () {
      return {
        // width: `calc(100% - 300px)`,
        // width: `calc(70%)`,
        dataForm: {
          prjId: '',
          contractId: '',
          subcontractorId: '',
        },
        treeData: [],
        treeProps: {
          children: 'children',
          label: 'name',
          isLeaf: 'isLeaf'
        },
        treeLoading: false,
        tableShow: true,
        tableHeight: 300,
        dataListLoading: false,
        dataList: [],

        // 当前选中的形象单元
        selectScheduleUnitData: null,

        myShakeClass: '',
        shakeTimer: '',
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 160;
      })
    },
    watch: {},
    components: {},
    created () {
      this.getTableHeight();
    },
    methods: {
      getTableHeight () {
        let tableH = 300;
        let tableHeightDetil = window.innerHeight - tableH;
        if (tableHeightDetil <= 300) {
          this.tableHeight = 300;
        } else {
          this.tableHeight = window.innerHeight - tableH;
        }
      },
      // 标段切换事件
      contractChange (data) {
        this.dataForm.prjId = data.prjId
        this.dataForm.contractId = data.contractId
        this.dataForm.subcontractorId = data.subcontractorId

        this.selectScheduleUnitData = null

        this.getTreeData()
        this.getWbsDataList()
      },

      // 获取形象单元分类集合
      getTreeData () {
        this.treeLoading = true
        this.$http.get(
          '/opm/scheduleUnitType/list',
          {
            params: {
              prjId: this.dataForm.prjId,
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.treeData = res.data
          this.dataForm.scheduleUnitId = ''
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.treeLoading = false
        })
      },

      // 异步加载形象单元
      loadUnitNode (node,resolve) {
        if (node.level !== 0) {
          // 查询形象单元集合
          this.$http.get(
            '/opm/scheduleUnit/list',
            {
              params: {
                prjId: this.dataForm.prjId,
                typeId: node.data.id
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            if (res.data.length == 0) {
              node.isLeaf = true
            } else {
              for (let i = 0; i < res.data.length; i++) {
                res.data[i][this.treeProps.children] = []
                res.data[i][this.treeProps.isLeaf] = true
              }
            }
            resolve(res.data);
          }).catch(() => {
            return this.$message.error('出错了')
          })
        }
      },

      // 形象单元树节点点击事件
      treeNodeClick (data,node,ele) {
        // 根据当前点击的节点是否有typeId属性，来判断当前点击的是形象单元分类还是形象单元
        let typeId = data.typeId ? data.typeId : '-1'
        // 当前点击的是形象单元分类
        if (typeId == '-1') {
          this.$refs.unitTree.setCurrentKey(null)
          this.selectScheduleUnitData = null
          this.$message({
            message: '请选择具体的形象单元',
            type: 'warning',
            duration: 2000
          })
        } else {
          // 底部文字提示信息上下抖动
          this.myShakeClass = 'my-shake'
          if (this.shakeTimer) {
            clearTimeout(this.shakeTimer)
          }
          this.shakeTimer = setTimeout(()=> {
            this.myShakeClass = ''
          }, 1000)

          if (!this.selectScheduleUnitData || this.selectScheduleUnitData.id != data.id) {
            this.selectScheduleUnitData = data
          }
        }
      },

      // 加载wbs根节点
      getWbsDataList () {
        this.dataListLoading = true
        this.tableShow = false
        this.$nextTick(() => {
          this.tableShow = true
          this.$http.get(
            '/opm/scheduleUnit/findWbsChildren',
            {
              params: {
                pid: 0,
                ...this.dataForm
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.dataList = res.data
          }).catch(() => {
            return this.$message.error('出错了')
          }).finally(() => {
            this.dataListLoading = false
          })
        })
      },

      // 异步加载wbs子节点
      loadWbsChildrenData (row,node,resolve) {
        let pid = row.id
        this.$http.get('/opm/scheduleUnit/findWbsChildren',
          {
            params: {
              'pid': pid,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
        }).catch(() => {
          return this.$message.error('出错了')
        })
      },

      // 刷新wbs子节点（本地递归查询刷新，不重新请求后台数据）
      reloadWbsTree (parentData,parentId) {
        let childrenList = this.$refs.wbsTable.store.states.lazyTreeNodeMap[parentId]
        if (childrenList && childrenList.length > 0) {
          for (let i = 0; i < childrenList.length; i++) {
            let data = childrenList[i]
            if (data) {
              if (data.scheduleUnitId != parentData.scheduleUnitId) {
                // 更新形象单元列显示值
                data.scheduleUnitIdName = parentData.scheduleUnitIdName
                data.scheduleUnitId = parentData.scheduleUnitId
              }
              this.reloadWbsTree(data,data.id)
            }
          }
        }
      },
      batchUnbind (row) {
        this.$confirm('该操作将同时取消下级节点的绑定','确定进行[批量取消绑定]操作?',{
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          this.updateWbsScheduleUnitId(row,null,null)
        }).catch()
      },
      unbind (row) {
        this.$confirm('确定进行[取消绑定]操作?','提示',{
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          this.updateWbsScheduleUnitId(row,null,null)
        }).catch()
      },
      batchBind (row) {
        if (!this.selectScheduleUnitData) {
          return this.$message.error('请先选择要绑定的形象工程！')
        }
        this.$confirm('该操作将同时进行下级节点的绑定','确定进行[批量绑定]操作?',{
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          let scheduleUnitId = this.selectScheduleUnitData.id
          let scheduleUnitIdName = this.selectScheduleUnitData.name
          this.updateWbsScheduleUnitId(row,scheduleUnitId,scheduleUnitIdName)
        }).catch()
      },
      bind (row) {
        if (!this.selectScheduleUnitData) {
          return this.$message.error('请先选择要绑定的形象工程！')
        }
        let scheduleUnitId = this.selectScheduleUnitData.id
        let scheduleUnitIdName = this.selectScheduleUnitData.name
        this.updateWbsScheduleUnitId(row,scheduleUnitId,scheduleUnitIdName)
      },
      updateWbsScheduleUnitId (row,scheduleUnitId,scheduleUnitIdName) {
        const loading = this.$loading({
          text: '正在执行，请稍等...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        // 更新wbs的形象单元ID
        this.$http['put']('/opm/scheduleUnit/wbs/cascadeUpdateScheduleUnitId',{id: row.id,scheduleUnitId: scheduleUnitId}).then(({data: res}) => {
          if (res.code != 0) {
            return this.$message.error(res.msg)
          }
          // 更新形象单元列显示值
          row.scheduleUnitIdName = scheduleUnitIdName
          row.scheduleUnitId = scheduleUnitId

          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 2000
          })
          // 刷新子节点
          if (!row.isLeaf) {
            this.reloadWbsTree(row,row.id)
          }
        }).catch((error) => {
          return this.$message.error('出错了！' + error)
        }).finally(() => {
          loading.close()
        })
      }
    }
  }
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 160px);
  }

  @keyframes shake {
    0% { transform: translate(0, 0); }
    50% { transform: translate(0, 5px); }
    100% { transform: translate(0, 0); }
  }
  .my-shake {
    animation: shake 0.3s infinite;
  }
</style>