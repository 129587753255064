<template>
  <div ref="myDiv" class="mod-__boqtemplatemain">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-button-group style="margin-top: -3px;">
          <toolbar-button role="addRoot" v-if="$hasPermission('mps:boqtemplate:save')" @click="addChildNode({id: 0})"></toolbar-button>
          <toolbar-button role="delete" v-if="$hasPermission('mps:boqtemplate:delete')" @click="myDeleteHandle()"></toolbar-button>
        </el-button-group>
        <el-button-group style="margin-top: -3px;">
          <el-tooltip content="刷新名称路径" placement="top" effect="light">
            <el-button icon="el-icon-refresh" v-if="$hasPermission('mps:boqtemplate:update')" @click="refreshHandle"></el-button>
          </el-tooltip>
          <el-tooltip content="全局排序" placement="top" effect="light">
            <el-button icon="el-icon-sort" v-if="$hasPermission('mps:boqtemplate:update')" @click="refreshSortNoHandle"></el-button>
          </el-tooltip>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table
      v-if="tableShow"
      ref="table"
      v-loading="dataListLoading"
      :data="dataList"
      row-key="id"
      lazy
      :load="loadTableData"
      @selection-change="dataListSelectionChangeHandle"
      highlight-current-row
      border
      :max-height="tableHeight"
      style="width: 100%;"
      :cell-style="{padding: '2px 0'}">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="code" label="编码" width="240" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="名称" min-width="150" show-overflow-tooltip></el-table-column>
      <el-table-column prop="unitName" label="单位" align="center" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column :label="$t('handle')" fixed="right" align="center" width="140">
        <template slot-scope="{row}">
          <table-button role="addChild" v-if="$hasPermission('mps:boqtemplate:save')" @click="addChildNode(row)"></table-button>
          <table-button role="update" v-if="$hasPermission('mps:boqtemplate:update')" @click="addOrUpdateHandle(row.id)"></table-button>
          <table-button role="delete" v-if="$hasPermission('mps:boqtemplate:delete')" @click="myDeleteHandle(row.id, row.pid)"></table-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @addNode="addNodeCallback" @updateNode="updateNodeCallback" @close="closeDialogHandle"></add-or-update>
  </div>
</template>

<script>
  import mixinViewModule from '@/mixins/view-module'
  import AddOrUpdate from './boqtemplate-add-or-update'

  export default {
    mixins: [mixinViewModule],
    data () {
      return {
        mixinViewModuleOptions: {
          deleteURL: '/mps/boqTemplate',
          deleteWithRelatedFiles: false,
          deleteSuccessCallback: this.deleteSuccessCallback
        },
        dataForm: {},
        dataList: [],
        dataListLoading: false,
        mainId: null, // 范本主表ID
        tableHeight: 600,
        // 存储已加载的节点的map
        treeNodeMap: new Map(),
        tableShow: true,
      }
    },
    components: {
      AddOrUpdate
    },
    mounted () {
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 340
      })
    },
    methods: {
      // el-table懒加载节点
      loadTableData (data,node,resolve) {
        let pid = data.id
        // 将已加载的节点相关参数updateNode存入map，用于后续增加子节点、删除子节点时刷新父节点
        this.treeNodeMap.set(pid,{data,node,resolve})
        this.$http.get(
          '/mps/boqTemplate/findChildren',
          {
            params: {
              mainId: this.mainId,
              pid: pid
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            // 返回出错
            return this.$message.error(res.msg)
          }
          resolve(res.data);
          // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
          // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
          if (res.data.length == 0) {
            this.$set(this.$refs.table.store.states.lazyTreeNodeMap,pid,[])
          }
        }).catch(() => {
          return this.$message.error('出错了')
        })
      },
      getDataList () {
        this.treeNodeMap = new Map()
        // 重置一些el-table缓存的变量
        // 此处如果不重置，可能会导致一些无法预料的情况
        // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
        this.tableShow = false
        this.$nextTick(() => {
          this.tableShow = true
        })
        this.dataListLoading = true
        this.$http.get(
          '/mps/boqTemplate/findChildren',
          {
            params: {
              mainId: this.mainId,
              pid: 0,
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataList = res.data
          this.dataListLoading = false
        }).catch(() => {
          return this.$message.error('出错了')
        })
      },
      addNodeCallback (pid) {
        this.refreshParentNode(pid)
      },
      updateNodeCallback (pid,isPidChanged) {
        if (isPidChanged) {
          // 父节点修改，直接刷新列表
          this.getDataList()
        } else {
          // 父节点未修改，刷新该节点子节点列表
          this.refreshParentNode(pid)
        }
      },
      // 刷新父节点（只能刷新展开过的节点）
      refreshParentNode (pid) {
        let nodeInfo = this.treeNodeMap.get(pid)
        if (nodeInfo) {
          // 这个loading属性控制此节点在load时是否重新加载
          // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
          nodeInfo.node.loading = true
          nodeInfo.data.hasChildren = true // 设置父节点为非叶子节点，出现展开按钮
          this.loadTableData(nodeInfo.data,nodeInfo.node,nodeInfo.resolve)
        } else if (pid == 0) {
          this.getDataList()
        } else if (this.dataList && this.dataList.filter((item) => {
          return item && item.id == pid
        }).length > 0) {
          this.getDataList()
        } else {
          this.getDataList()
        }
      },
      // 新增子节点
      addChildNode (row) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.mainId = this.mainId
          this.$refs.addOrUpdate.dataForm.pid = row.id
          this.$refs.addOrUpdate.init()
        })
      },
      deleteSuccessCallback({id,pid}) {
        if (id && pid) {
          this.refreshParentNode(pid)
        } else {
          this.getDataList()
        }
      },
      // 删除
      myDeleteHandle (id,pid) {
        this.deleteHandle(id, {deleteSuccessCallbackArgs: {id,pid}, autoQuery: false})
      },

      // 刷新节点的名称路径(pids,pnames)
      refreshHandle () {
        this.$confirm('此操作将刷新清单范本的名称路径, 是否继续?').then(() => {
          const loading = this.$loading({
            lock: true,
            text: '请稍候...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$http.put(`/mps/boqTemplate/${this.mainId}/refresh`).then(({data: res}) => {
            if (res.code != 0) {
              return this.$alert(res.msg,'',{
                confirmButtonText: '关闭',
                type: "error"
              });
            }
            this.$alert('刷新成功','',{
              confirmButtonText: '关闭',
              type: "success"
            });
          }).finally(() => {
            loading.close()
          })
        }).catch()
      },

      // 全局排序
      refreshSortNoHandle() {
        this.$confirm('是否要运行全局排序?').then(() => {
          const loading = this.$loading({
            lock: true,
            text: '请稍候...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$http.put(`/mps/boqTemplate/${this.mainId}/refreshSortNo`).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '已完成',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          }).finally(() => {
            loading.close()
          })
        }).catch()
      },
    }
  }
</script>
