<template>
  <el-dialog :visible.sync="visible" v-dialog-drag title="数据字典选项" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners" width="80%">
    <div slot="title">
      <span class="el-dialog__title">数据字典选项</span>
      <span class="my-subtitle">{{ dataForm.dictTypeCode+'-'+dataForm.dictTypeName }}</span>
    </div>
    <div class="mod-sys__dict">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.dictValue" :placeholder="$t('dict.dictValue')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.dictLabel" :placeholder="$t('dict.dictLabel')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('sys:dict:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('sys:dict:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border
                @selection-change="dataListSelectionChangeHandle"
                @sort-change="dataListSortChangeHandle" style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="dictValue" :label="$t('dict.dictValue')" sortable="custom" header-align="center" align="center"></el-table-column>
        <el-table-column prop="dictLabel" label="字典标签" header-align="center" sortable="custom" align="center"></el-table-column>
        <!-- <el-table-column prop="sort" :label="$t('dict.sort')" sortable="custom" header-align="center" align="center" width="100"></el-table-column> -->
        <el-table-column prop="remark" :label="$t('dict.remark')" sortable="custom" header-align="center" align="left"></el-table-column>
        <el-table-column prop="createDate" :label="$t('dict.createDate')" sortable="custom" header-align="center" align="center" width="180"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="130">
          <template slot-scope="scope">
            <table-button role="edit" v-if="$hasPermission('sys:dict:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('sys:dict:delete')" @click="deleteHandle(scope.row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-dialog>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './dict-data-add-or-update'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/sys/dict/data/page',
        getDataListIsPage: true,
        deleteURL: '/sys/dict/data',
      },
      visible: false,
      dataForm: {
        dictTypeId: '0',
        dictLabel: '',
        dictValue: '',
        dictTypeName: '', // 字典类型名称
        dictTypeCode: '', // 字典类型
      }
    }
  },
  components: {
    AddOrUpdate
  },
  methods: {
    init(dictTypeId,dictTypeCode,dictTypeName) {
      this.dataList = [] // 清除缓存数据
      this.visible = true
      this.$nextTick(() => {
        this.dataForm.dictTypeId = dictTypeId
        this.dataForm.dictTypeCode = dictTypeCode
        this.dataForm.dictTypeName = dictTypeName
        this.getDataList()
      })
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.dictTypeId = this.dataForm.dictTypeId
        this.$refs.addOrUpdate.init()
      })
    }
  }
}
</script>
<style scoped>
.my-subtitle{
  font-size: 12px;
  margin-left: 10px;
  color: #909399;
}
</style>
