<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__major">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="专业名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.abbr" placeholder="专业简称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('dfms:major:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
        <el-form-item>
          <el-alert title="用于图档管理模块的专业选择" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
        </el-form-item>
      </el-form>
      <el-table highlight-current-row v-loading="dataListLoading" :data="dataList" border @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle" style="width: 100%;">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="专业名称" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="abbr" label="专业简称" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="creatorName" label="创建人" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateDate" label="修改时间" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="updaterName" label="修改人" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="190">
          <template slot-scope="scope">
            <table-button role="view" @click="viewHandle(scope.row.id)"></table-button>
            <table-button role="update" v-if="$hasPermission('dfms:major:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('dfms:major:delete')" @click="deleteHandle(scope.row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './major-add-or-update'
import ViewPage from './major-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/mps/major/page',
        getDataListIsPage: true,
        exportURL: '/mps/major/export',
        deleteURL: '/mps/major',
      },
      dataForm: {
        name: '',
        abbr: ''
      }
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage
  }
}
</script>
